import { Experiments } from '@wix/thunderbolt-becky-types'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { ExperimentsSymbol } from '@wix/thunderbolt-symbols'
import { ComponentWillMount, ViewerComponent } from 'feature-components'

export const GhostRefComponentWillMount = withDependencies(
	[ExperimentsSymbol],
	(experiments: Experiments): ComponentWillMount<ViewerComponent> => {
		return {
			componentTypes: ['GhostRefComp'],
			componentWillMount(comp) {
				const shouldRemoveMarginsFromGhostRefComp =
					experiments['specs.thunderbolt.removeMarginsFromGhostRefComp']

				if (
					shouldRemoveMarginsFromGhostRefComp &&
					(shouldRemoveMarginsFromGhostRefComp === 'true' || shouldRemoveMarginsFromGhostRefComp === true)
				) {
					comp.updateProps({
						shouldRemoveMargins: true,
					})
				}
			},
		}
	}
)
